/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-02-28 08:29:15
 */

import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { Rodape } from "../Footer/Rodape";
import logo from "../Images/Logo.png";
import { TopBar } from "../Navbar/TopBar";

const onClick = () => {
    window.location.href = "#recado";
  };

function refreshPage() {
    alert("Mensagem enviada!")
    window.location.reload();
}

export const FaleConosco = () => {
    return(
        <>
            <TopBar/>
            <Atendimento />
            <Container>
                <Cartao />
                <Recado />
                <Local />
            </Container>
            <Rodape />
        </>
    )
}

const Atendimento = () => (
    <>
			<Card className="bg-primary">
				<Card.Img  />
				<Card.ImgOverlay>
					<Card.Title className="display-5 text-white text-center">
						<p className="mt-sm-2">Fale Conosco</p>
					</Card.Title>
				</Card.ImgOverlay>
			</Card>
    </>
)

const Cartao = () => (
    <>
        <Row className="ms-4 p-2 mt-5">
        <Col className="text-center mb-5" xl={4}>
        <Card style={{ width: '18rem',boxShadow: '2px 2px 9px #bcbcbc',}}>
            <Card.Body>
                <Card.Title className="bi bi-telephone"> Telefone</Card.Title>
                    <Card.Text> <br /> (62) 3295-5000 </Card.Text>
                <Button variant="primary" href="tel:(62) 32955000"> Ligar </Button>
            </Card.Body>
        </Card>
        </Col>
        <Col className="text-center mb-5" xl={4}>
        <Card style={{ width: '18rem',boxShadow: '2px 2px 9px #bcbcbc',}}>
            <Card.Body>
                <Card.Title className="bi bi-whatsapp"> WhatsApp</Card.Title>
                    <Card.Text> <br /> (62) 3295-5000</Card.Text>
                <Button href="https://wa.me/556232955000" variant="primary">Ir para WhatsApp</Button>
            </Card.Body>
        </Card>
        </Col>
        <Col className="text-center mb-3" xl={3}>
        <Card style={{ width: '18rem',boxShadow: '2px 2px 9px #bcbcbc',}}>
            <Card.Body>
                <Card.Title className="bi bi-mailbox"> Deixe seu recado</Card.Title>
                    <Card.Text>
                    É sempre um prazer receber seu contato, dúvida ou sugestão
                    </Card.Text>
                <a onClick={onClick} className="btn btn-primary" href="#A">Mandar Recado</a>
            </Card.Body>
        </Card>
        </Col>
        </Row>
    </>
)

const Recado = () => (
    <>
        <Row>
            <Col md="4">
                <Image
                fluid width={350} className="text-center mt-2 mb-2" src={logo}>
                </Image>
            </Col>
            <Col>
                <h3 className="text-center mb-5 mt-xl-5 display-1">Fale com a Barão</h3>
            </Col>
        </Row>

        <h3 className="bi-archive"> Utilize o formulário abaixo para entrar em contato</h3>
        <i>
        <b id="recado"> <a href="#recado"></a> É sempre um prazer receber o seu contato, dúvida ou sugestão. </b>
        Nós da Barão Alimentos estamos em constante busca pelo aprimoramento
        das nossas atividades e contamos com a sua participação nesse processo
        ! Para envio de currículo, mande um e-mail para
        <b> rh@baraoalimentos.com.br</b>
        </i>

        <Container className="col-10">
        <FloatingLabel
        controlId="floatingInput"
        label="E-mail"
        className="mb-3 mt-4"
        >
            <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>

        <FloatingLabel
        controlId="floatingName"
        label="Nome"
        className="mb-3"
        >
            <Form.Control type="text" placeholder="Nome" />
        </FloatingLabel>

        <FloatingLabel
        controlId="floatingTelephone"
        label="Número do Telefone (DDD) + Número"
        className="mb-3">
            <Form.Control type="tel" placeholder="DDD + Número" />
        </FloatingLabel>

        <FloatingLabel
        className=" mb-3"
        controlId="floatingMessage"
        label="Mensagem">
            <Form.Control
            style={{height:"150px"}}
            as="textarea"
            placeholder="Recado"/>
        </FloatingLabel>

        <Form className="mb-2">
            <Button onClick= {refreshPage}>Enviar</Button>
        </Form>
        </Container>
    </>
)

const Local = () => (
    <>
        <Row className="mt-5">
            <Col sm={5}>
                <iframe src="https://www.google.com/maps/embed?pb=!4v1709913215590!6m8!1m7!1s8eBz99jGyRQdaUCFOX-hKA!2m2!1d-16.80958520087632!2d-49.26482763163636!3f26.894398541148355!4f-22.73798453005638!5f0.7820865974627469" width="389" height="300" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Col>
            <Col xs={7}>
                <b>Endereço</b>
                <p> Rua Xavier de Almeida, S/N, Quadra 25, Modulo 1 -
                    Polo Empresarial - Etapa XII
                    <p> Aparecida de Goiânia - Goiás </p> </p> <br />
                <b> Horário de atendimento: </b>
                <p> Segunda a Sexta: 7h às 11h e 13h às 18h. </p>
                <p> Sábado: 7h às 11h. </p>
            </Col>
        </Row>

    </>
)
