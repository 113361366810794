/**
 * @author Felipe Oliveira do Brasil
 * @since 2024-01-23 10:59:40
 */

import { Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Face from "../Images/SocialMedia/FacebookTransparente.png"
import Insta from "../Images/SocialMedia/Instagram.png"
import siga from "../Images/SocialMedia/Siga.jpg"

export const Rede = () => {
	return (
        <>
            <Image className="mt-5" src={siga} fluid ></Image>
            <Container className="mt-4 text-center">
                <Row>
                    <Col xs={6} className="mt-4 ">
                        <Link to="https://www.instagram.com/baraoalimentosoficial/">
                            <Image rounded fluid width={80} src={Insta}></Image>
                        </Link>
                    </Col>
                    <Col xs={6}>
                        <Link to="https://www.facebook.com/baraoalimentosoficial">
                            <Image rounded fluid width={130} src={Face}></Image>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}