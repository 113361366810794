import { Rodape } from "../Footer/Rodape"
import { TopBar } from "../Navbar/TopBar"

export const Trabalhe = () => {
    return(
        <>
            <TopBar />
            <Rodape />
        </>
    )
}